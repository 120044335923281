// initialize copy to clipboard buttons
export default () => {
  document.querySelectorAll('.js-copy-to-clipboard').forEach($btn => {
    $btn.addEventListener('click', (e) => {
      // the button needs a data-target attribute containing the ID of the input from which to copy
      const $target = document.querySelector(`#${e.currentTarget.dataset.target}`)
      $target.select()
      document.execCommand("copy")
      
      // to show success, we turn the button green and flip the icons inside
      e.currentTarget.classList.add('is-light', 'is-success')
      e.currentTarget.querySelectorAll('svg').forEach($icon => $icon.classList.toggle('is-hidden'))
      e.currentTarget.setAttribute('disabled', true)
    })
  })
}


