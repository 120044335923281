// asynchronously load remote deferred content blocks
export default () => {
  document.querySelectorAll('.js-deferred').forEach($container => {
    const src = $container.dataset.src

    if (src === undefined) {
      console.error('No data-src present on remote container:', $container)
      return
    }

    fetch(src)
      .then(r => r.text())
      .then(html => $container.innerHTML = html)
  })
}
