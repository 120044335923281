/* eslint no-console:0 */

require("@rails/ujs").start()
import deferred from '../deferred'
import copyToClipboard from '../copy-to-clipboard'
import usageChart from '../usage-chart'

document.addEventListener('DOMContentLoaded', () => {
  // Simple code to delete Bulma notification elements with delete button
  // From https://bulma.io/documentation/elements/notification/
  (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    const $notification = $delete.parentNode;
    $delete.addEventListener('click', () => {
      $notification.parentNode.removeChild($notification);
    });
  });

  // load remote content blocks
  deferred()

  // initialize copy to clipboard buttons
  copyToClipboard()

  // initialize copy to clipboard buttons
  usageChart()
});
