import uPlot from "uplot"

// compactFormatter formats thousands with K, millions with M, etc.
const compactFormatter = (num) => {
  const formatter = Intl.NumberFormat("en", { notation: "compact" })
  return formatter.format(num)
}

// colors used in the chart, the first value is the line
// and the second value is the fill
const colors = {
  search:       ["rgb(132,204,22)", "rgba(132,204,22, 0.05)"],
  autocomplete: ["rgb(6,182,212)", "rgba(6,182,212, 0.05)"],
  structured:   ["rgb(236,72,153)", "rgba(236,72,153, 0.05)"],
  place:        ["rgb(16,185,129)", "rgba(16,185,129, 0.05)"],
  nearby:       ["rgb(139,92,246)", "rgba(139,92,246, 0.05)"],
  reverse:      ["rgb(249,115,22)", "rgba(249,115,22, 0.05)"],
}

// global chart options
let opts = {
  id: "usage",
  width: 1000, // note: this managed by a ResizeObserver below
  height: 400,
  axes: [
    {},
    {
      scale: "k",
      // format the Y axis using a custom formatter and only render integer values (no floats)
      // because a user can’t make 1.2 API requests
      values: (self, ticks) => ticks.map(rawValue => {
        if (Number.isInteger(rawValue)) {
          return compactFormatter(rawValue)
        }
      }),
    },
  ]
};

export default () => {
  document.querySelectorAll(".js-usage-chart").forEach(container => {
    const x = JSON.parse(container.dataset.x)
    const rawData = JSON.parse(container.dataset.datasets)

    let labels = []
    let datasets = []

    rawData.forEach(([endpoint, data]) => {
      labels = [...labels, endpoint]
      datasets = [...datasets, data]
    })

    opts.series = [{}, ...labels.map(label => ({
      show: true,
      label: label,
      scale: "k",
      stroke: colors[label][0],
      fill: colors[label][1],
      width: 1,
    }))]

    let uplot = new uPlot(
      opts,
      [x, ...datasets],
      container
    )

    // resize the chart width when the container width changes
    const resizer = new ResizeObserver(entries => {
      uplot.setSize({
        width: entries[0].borderBoxSize[0].inlineSize,
        height: opts.height,
      })
    })

    resizer.observe(container)
  })
}
